<template>
  <div class="ps-vendor-dashboard">
    <div class="container-fluid">
      <ul class="ps-section__links">
        <li>{{ shop.name }}</li>
        <li :class="{ active: $route.name === 'merchant_dashboard' }">
          <router-link :to="{ name: 'merchant_dashboard' }"
            >Dashboard</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'merchant_vouchers' }">
          <router-link :to="{ name: 'merchant_vouchers' }"
            >Vouchers</router-link
          >
        </li>
        <li
          v-if="shop.id == 1"
          :class="{ active: $route.name === 'merchant_prescriptions' }"
        >
          <router-link :to="{ name: 'merchant_prescriptions' }"
            >Prescriptions</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'merchant_products' }">
          <router-link :to="{ name: 'merchant_products' }"
            >Products</router-link
          >
        </li>
        <li :class="{ active: $route.name === 'merchant_orders' }">
          <router-link :to="{ name: 'merchant_orders' }">Orders</router-link>
        </li>
        <li :class="{ active: $route.name === 'merchant_addresses' }">
          <router-link :to="{ name: 'merchant_addresses' }">Shops</router-link>
        </li>
        <li :class="{ active: $route.name === 'merchant_hours' }">
          <router-link :to="{ name: 'merchant_hours' }">Hours</router-link>
        </li>
        <li :class="{ active: $route.name === 'merchant_settings' }">
          <router-link :to="{ name: 'merchant_settings' }">Profile</router-link>
        </li>
        <!--        <li :class="{'active': $route.name === 'merchant_settings'}">-->
        <!--          <router-link :to="{name: 'merchant_store'}">View Store</router-link>-->
        <!--        </li>-->
        <li>
          <a href="#" @click.prevent="logout()">Logout</a>
        </li>
      </ul>
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      shop: null,
      isLoading: false,
      isError: false,
      message: "",
      errors: null,
    };
  },
  mounted() {
    this.getShop();
  },
  methods: {
    getShop() {
      axios.get("shops/view").then((response) => {
        this.shop = response.data;
      });
    },
    getOrders() {
      axios
        .get("user/orders")
        .then((response) => {
          console.log(response.data);
          this.orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "home" });
    },
  },

  computed: {
    ...mapState({
      shop: (state) => state.auth.shop,
    }),
  },
};
</script>

<style lang="css" scoped>
#sidebarMenu {
  height: 1000px;
}
</style>
